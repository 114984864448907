footer{
	.yellow-footer{
		background-color: $yellow;
		box-shadow: 0px -5px 40px rgba(0, 0, 0, 0.5);
		border-radius: 10px 10px 0px 0px;
		h2{
			font-style: normal;
			font-weight: 400;
			@include adaptiv-font(24, 12);
			color: $dark;
			text-align: center;
			padding: 1% 0;
		}
	}
	.footer-content{
		background-image: url(../img/footer-bg.jpg);
		background-position: center;
   		background-repeat: no-repeat;
   		background-size: cover;
	
		.container{
			text-align: center;
			h2{
				font-style: normal;
				font-weight: 600;
				@include adaptiv-font(40, 16);
				line-height: 78px;
				color: $yellow;
				padding-top: 5%;
			}
			.bottomMenu{
				display: flex;
				justify-content: center;
				border-bottom: 1px solid rgba(255, 255, 255, 0.5);
				li{
					padding: 4% 1% 1%;
					a{
						font-style: normal;
						font-weight: 400;
						@include adaptiv-font(20, 12);
						line-height: 29px;
						color: $yellow;
						&:hover{
							color: $light-yellow;
					    }
					}
				}
			}
			.footer__contacts{
				padding: 5% 0;
				a{
					font-style: normal;
					font-weight: 400;
					@include adaptiv-font(24, 12);
					line-height: 35px;
					padding: 1%;
					color: $yellow;
					display: flex;
				    align-items: center;
				    justify-content: center;

				    img{
			    	    width: 30px;
			    	    margin-right: 2%;
				    }
				    &:hover{
				    	color: $light-yellow;

				    }
				}
			}
		}
	}
}

// responsive/////////////////////////////////////////////////////////////////


@media(max-width: 900px){
	.footer{
		.footer-content{
			.container{
				display: flex;
				flex-wrap: wrap;
				justify-content: space-around;

				h2{flex: 0 0 100%}

				.menu{
					flex: 0 0 30%;
					flex-direction: column;
					align-items: flex-start;
					border: none;
				}
				.footer__contacts{
					display: flex;
					flex: 0 0 30%;
					flex-direction: column;
					align-items: flex-start;
					a{
						width: 100%;
						justify-content: flex-start;
						margin-top: 5%;
						img{
							width: 15%;
						}
					}
				}
			}
		}
	}
}