.stuff{
  padding: 8px ;
  p{text-align: center;}
  .swiper{
    margin-bottom: 5%;
    .swiper-wrapper{
      align-items: center;
      width: 90%;
    }
    //.swiper-button-next, .swiper-button-prev {
    //  color: #624063;
    //}
  }
  .swiper-slide{
    text-align: center;
    vertical-align: center;
    height: 300px;
    img{
      height: 100%;
      border-radius: 5px;
      //box-shadow: -2px -2px 20px rgb(111 74 100 / 34%);
    }
  }
}
@media(max-width: 900px){
  .stuff{
    .swiper-slide{
      padding-bottom: 5%;
    }
    .swiper-button-next, .swiper-button-prev {
      display: none;
    }
  }
}