.services-page{
	margin-bottom: 5%;
	p{
		text-align: center;
		font-style: normal;
		font-weight: 400;
		@include adaptiv-font(24, 12);
		line-height: 35px;
		color: $dark;
	}
	.services__content{
		margin-bottom: 5%;
		.services__item{
			display: flex;
			margin-top: 5%;
			justify-content: space-between;
			img{
				margin: 0 auto;
			}
			.service__item_text{
				text-align: left;
				padding-left: 5%;
				width: 60%;
				h1{
					font-style: normal;
					font-weight: 700;
					@include adaptiv-font(48, 16);
					color: $dark;
					text-align: left;
					margin-left: 0;
				}
				p, ul li{
					font-style: normal;
					font-weight: 400;
					@include adaptiv-font(16, 12);
					color: rgba(34, 34, 34, 0.7);
					text-align: left;
				}
				p{
					margin-bottom: 2%;
				}
				ul li{
					list-style-type: circle ;
					margin-bottom: 1%;
				}
				.price{
					font-style: normal;
					font-weight: 700;
					@include adaptiv-font(24, 12);
					color: $fiolet;
					margin-bottom: 0;
					padding-top: 5%;
				}
				button{
					margin-top: 2%;
				}
			}
		}
	}
}
// responsive/////////////////////////////////////////////////////////////////


@media(max-width: 900px){
	.services-page{
		p{
			padding: 8px;
		}
		.services__content{
			.services__item{
				background: $white;
				box-shadow: 2px 2px 50px rgba(0, 0, 0, 0.35);
				border-radius: 12px;
				margin: 4% 2%;
				img{
					display: none;
				}
				.service__item_text{
					padding: 5%;
					width: 100%;
					h1{
						margin-top: 0;
					}
					.price{
						padding-top: 1% !important;
					}
					button{
						width: 100%;
					}
					ul li {
						margin-left: 5%;
					}
				}
			}
		}
	}
}