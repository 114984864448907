.MuiCircularProgress-colorPrimary{
  color: $yellow !important;
}
.MuiCircularProgress-colorSecondary{
  color: $fiolet !important;
}
//Waiting to be sent
.waiting-to-be-sent{
  position: absolute;
  background: rgba(255, 255, 240, 0.15);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  z-index: 999;
  top: 0;
}

//CSSTransition
.alert-enter{
  opacity: 0;
  transform: scale(.9);
}
.alert-enter-active{
  opacity: 1;
  transform:translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.alert-exit{
  opacity: 1;
}
.alert-exit-active{
  opacity: 0;
  transform: scale(.9);
  transition: opacity 300ms, transform 300ms;
}

//Custom Alerts
.error-alert{
  background-color: #D32F2F;
  animation: 1.2s ease-in-out 0s normal none infinite running trambling-animation;
}
.success-alert{
  background-color: #2E7D32;
}
.error-alert, .success-alert{
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  //padding: 8px;
  height: 45px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 95%;
  img{
    margin: 0 12px;
  }
  p{
    color: #ffffff !important;
    font-weight: 500;
    @include adaptiv-font(14, 12);
    margin: 0 !important;
  }
}

.modal{
  .error-alert, .success-alert{
    width: 100% !important;
    animation: none;
  }
}
@media(max-width: 900px) {
  .error-alert, .success-alert{
    width: 100%;
  }
}