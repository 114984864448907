main{
	h1{
		font-style: normal;
		font-weight: 600;
		@include adaptiv-font(36, 22);
		text-align: center;
		color: $dark;
		width: 70%;
		margin: 0 auto 3%;
	}
	.utp{
		//display: flex;
		//max-width: 90%;
		margin: 4% auto;
		.utp__img{
			flex: 40% 0 0;
			text-align: center;
			img{
				width: 60%;
			}
		}
		.utp__text{
			flex: 60% 0 0;
			padding-left: 4%;
			img{display: none;}
			p{
				font-style: normal;
				font-weight: 300;
				@include adaptiv-font(16, 12);
				//line-height: 24px;
				margin-bottom: 2.5%;
				b{
					color: $fiolet;
				}
			}
			.btn{
				margin-top: 2%;
			}
		}
	}
	.offer{
		background: $fiolet;
		h2{
			color: $light-yellow !important;
		}
		.offer__container, .advantage__container{
			justify-content: space-between;
			padding: 2% 0;
			.offer-item{
				h4{
					color: $light-yellow !important;
				}
				p{
					color: $light-yellow !important;
				}
			}
		}
	}
	.advantage{
		margin-bottom: 4%;
	}
	.offer, .advantage{
		padding: 40px;
		margin-top: 5%;
		h2{
			font-style: normal;
			font-weight: 600;
			@include adaptiv-font(42, 16);
			line-height: 36px;
			color: $dark;
			text-align: center;
		}
		.offer__container, .advantage__container{
			justify-content: space-between;
			padding: 2% 0;
			.offer-item{
				flex: 15% 0 0;
				padding:4% 0;
				display: flex;
				flex-direction: column;
				align-items: center;
				h4{
					font-style: normal;
					font-weight: 700;
					@include adaptiv-font(18, 12);
					line-height: 24px;
					color: $dark;
					padding: 10% 0;
					text-align: center;
				}
				p{
					text-align: center;
					font-style: normal;
					font-weight: 400;
					@include adaptiv-font(16, 11);
					line-height: 18px;
					color: #434B53;
				}
			}
		}
	}
	.piano{
		img{
			margin: 0 auto;
			width: 40%;
			flex: 0 0 30%;
		}
	}
	.seif{
		img{
			order: 1;
			margin: 0 auto;
			width: 10%;
			flex: 0 0 20%;
		}
	}
	.piano, .seif{
		display: flex;
		padding: 4%;
		margin-top: 5%;
		justify-content: space-between;

		.piano_text{
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			flex: 0 0 70%;
			h1{
				font-style: normal;
				font-weight: 600;
				@include adaptiv-font(48, 16);
				color: $dark;
				text-align: right;
				margin-right: 0;
				width: 100%;
			}
			p{
				font-style: normal;
				font-weight: 400;
				@include adaptiv-font(16, 12);
				color: rgba(34, 34, 34, 0.7);
				text-align: right;
				width: 80%;
			}
			.price{
				font-style: normal;
				font-weight: 700;
				@include adaptiv-font(24, 12);
				color: $fiolet;
				margin-bottom: 0;
				padding-top: 2%;
			}
			button{
				margin-top: 2%;
				text-align: right;
			}
		}
		.seif_text{
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			flex: 0 0 70%;
			h1{
				font-style: normal;
				font-weight: 600;
				@include adaptiv-font(48, 16);
				color: $dark;
				text-align: left;
				margin-right: 0;
				width: 100%;
			}
			p{
				font-style: normal;
				font-weight: 400;
				@include adaptiv-font(16, 12);
				color: rgba(34, 34, 34, 0.7);
				text-align: left;
				width: 80%;
			}
			.price{
				font-style: normal;
				font-weight: 700;
				@include adaptiv-font(24, 12);
				color: $fiolet;
				margin-bottom: 0;
				padding-top: 2%;
			}
			button{
				margin-top: 2%;
				text-align: left;
			}
		}
	}
	.vk-section{
		display: flex;
		flex-direction: column;
		background-color: $yellow;
		justify-content: center;
		text-align: center;
		align-items: center;
		img{
			width: 10%;
			margin: 5% 0 2% 0;
		}
		h1{width: 50%;}
		a{
			font-style: normal;
			font-weight: 500;
			@include adaptiv-font(24, 15);
			letter-spacing: -1px;
			text-decoration-line: underline;
			color: $fiolet;
			padding-bottom: 5%;
		}
	}
}

// responsive/////////////////////////////////////////////////////////////////

.phone-home{display: none}

@media(max-width: 900px){
	main {
		.phones{
			justify-content: space-around;
			//margin: 5%;
			margin-top: 8%;
			.phone-home{
				display: inline-block;
				font-style: normal;
				font-weight: 600;
				font-size: 18px;
				line-height: 25px;
				color: $fiolet;
			}
		}
		h1 {
			font-weight: 600;
			margin: 4% auto 0;
			width: 100%;
			padding: 8px;
		}
		.utp{
			.container{
				display: inline-block;
				.utp__img{display: none;}
				.utp__text{
					margin: 0;
					padding: 2%;
					width: 100%;
					float: left;
					img{
						display: block;
						float: right;
						width: 40%;
						padding: 3%;
					}
				}
				.btn{
					width: 100%;
				}
			}
		}
		.offer, .advantage{
			.offer__container, .advantage__container{
				.offer-item{
					padding-bottom: 15%;
					h4{
						font-weight: 400;
						padding: 2% 0;
					}
					p{
						color: rgba(51, 51, 51, 0.5);
					}
				}
			}
		}
		.piano{

			display: block;
			margin: 10% 2%;
			padding: 0;
			.piano_text{
				display: block;
				padding: 0;
				width: 100%;
				text-align: right;
				h1{
					margin-top: 0;
				}
				p{
					width: 100%;
				}
				.price{
					padding-top: 1% !important;
				}
				button{
					width: 50%;
					text-align: center;
					padding: 2%;
				}
			}
			img{
				display: block;
				float: left;
				padding: 4%;
				width: 40%;
			}
		}
		.seif{
			display: block;
			margin: 4% 2%;
			padding: 0;

			.seif_text{
				display: block;
				padding: 0;
				width: 100%;
				text-align: left;
				h1{
					margin-top: 0;
					padding-left: 0;
				}
				p{
					width: 100%;
				}
				.price{
					padding-top: 1% !important;
				}
				button{
					width: 50%;
					text-align: center;
					padding: 2%;
				}
			}
			img{
				display: block;
				float: right;
				padding: 4%;
				width: 30%;
			}
		}
		.vk-section{
			margin: 10% 0;
			img{
				width: 30%;
				margin: 5% 0 2% 0;
			}
			h1{width: 100%;}
			a{
				padding-bottom: 5%;
			}
		}
	}
}