.contacts-page{
	margin: 14% 0 5%;
		.container{
			text-align: left;
			h1{
				text-align: left;
				font-style: normal;
				font-weight: normal;
				@include adaptiv-font(48, 24);
				line-height: 38px;
				letter-spacing: -1px;
				color: $light-yellow;
			}
			p{
				margin-top: 1%;
				color: $light-yellow;
				opacity: 1;
			}
	}
	.white-block{
		position: relative;
		margin: -10% auto 3%;
		background: $fiolet;
		box-shadow: 0px 0px 30px rgba(98, 64, 99, 0.15);
		border-radius: 12px;
		width: 98%;
		&>.flex-container{
			padding: 3% 8% 5%;
			h3{
				font-style: normal;
				font-weight: bold;
				@include adaptiv-font(32, 20);
				line-height: 32px;
				text-align: center;
				color: $light-yellow;

			}
			.flex-block{
				flex: 50% 0 0;
			}
			.callback-form{
				form{
					.form__content{
						text-align:  left;
						.form-group{
							border-right: 1px solid $light-yellow;
							display: flex;
							flex-direction: column;
							input, textarea{
								width: 70%;
								border: 1px solid $light-yellow;
								border-radius: 6px;
								padding: 2.5%;
								&::placeholder{
									font-size: 14px;
									line-height: 24px;
									color: $dark;
								}
								&.error{
									border: 1px solid red;
									//animation: 1.2s ease-in-out 0s normal none infinite running trambling-animation;
									&::placeholder{
										color: red;
									}
								}
							}
							textarea{
								height: 150px;
							}
							.preText{
								margin-top: 2%;
								margin-bottom: 1%;
								text-align: left;
								font-size: 16px;
								line-height: 24px;
								color: $light-yellow;
								&:after{
									content: '*';
									color: $light-yellow;
								}
							}
						}
						.btn{
							width: 30%;
							margin-top: 5%;
							padding: 3%;
							font-size: 16px;
							line-height: 24px;
							box-shadow: none;
						}
					}
				}
			}

			.contact-information{
				.flex-container{
					flex-direction: column;
					text-align: left;
					margin: 10%;
					.inform-elem{
						margin: 5% 5% 0;
						width: 90%;
						display: flex;
						align-items: center;
						img{margin-right: 24px;}
					
						a,p{
							opacity: 1;
							@include adaptiv-font(16, 14);
							color: $light-yellow;
							cursor: pointer;
							transition: all .1s ease-in-out;
							line-height: 24px;

							&:hover{
								text-shadow: 0 0 0 #000, 0 0 0 #000;								
							}
						}
					}
				}
			}

			h3{
				font-style: normal;
				font-weight: bold;
				@include adaptiv-font(32, 20);
				line-height: 32px;
				text-align: center;
				&.blue-color{
					color: $light-yellow;
				}
			}
		}	
	}
}

// responsive/////////////////////////////////////////////////////////////////

@media(max-width: 900px){
	.contacts{
		h1{ display: none}
		.contacts-page{
			margin: 5% 0;
		}

		.title-block{
			display: none;
		}
		.white-block{
			border-radius:0;
			box-shadow: none !important;
			margin: 0 !important;
			width: 100%;
			padding: 8px ;
			background-color: $white;
			&>.flex-container{
				flex-direction: column;
				padding: 0 !important;
				.callback-form{
					order: 2;
					background-color: $white;
					h3{color: $fiolet;}
					form{
						margin: 5% 0;
						padding: 5%;
						background: $fiolet;
						box-shadow: 0 0 35px rgba(68, 140, 246, 0.15);
						border-radius: 12px;
						.form-group{
							border-right: none !important;
							input, textarea{
								width: 100% !important;
							}
						}
						.btn{
							width: 100% !important;
						}
					}
				}

				.contact-information{
					order: 1;
					background-color: $white;
					h3{color: $fiolet;}
					.flex-container{
						margin: 5% 0 !important;
						padding: 3%;
						background: $fiolet;
						box-shadow: 0 0 35px rgba(68, 140, 246, 0.15);
						border-radius: 12px;
						.inform-elem{
							margin: 0 0 3%!important;
							img{
								width: 20px;
							}
						}
					}
				}
			}
		}
	}

}
@media (max-width: 1280px) and (min-width: 901px){
	.white-block{
		&>.flex-container{
			.callback-form{
				form{
					.form-group{
						input, textarea{
							width: 90% !important;
						}
					}
				}
			}
		}
	}
}