@import '~swiper/components/pagination/pagination';
@import '~swiper/swiper.scss';
@import '~swiper/components/navigation/navigation.scss';

@import "~aos/dist/aos.css";
.swiper{
  padding-bottom: 3%;
  .swiper-button-next, .swiper-button-prev {
    transform: translateY(-100%);
  }
}
.swiper-pagination{
  bottom: 0 !important;
}
:root {
  --swiper-theme-color: #5E415F !important;
}
#swiperPaginationOffer{
  .swiper-pagination-bullet{
    background: $yellow;
  }
}
