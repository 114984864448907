.coupons-section{
  h2{
    font-style: normal;
    font-weight: 600;
    @include adaptiv-font(42, 16);
    line-height: 36px;
    color: $dark;
    text-align: center;
  }
  .container {
    >p{
      font-weight: 400;
      color: $dark;
      padding: 0 5%;
      @include adaptiv-font(20, 12);
      span{@include adaptiv-font(24, 16);}
      ol{
        padding: 0 2%;
        li{
          margin-top: 1%;
          ul{
            li{
              margin: 1%;
              list-style-type: circle;
            }
          }
        }
      }
    }
    .coupons {
      padding: 2%;
      flex-wrap: wrap;
      justify-content: space-around;

      .coupon {
        margin-top: 2%;
        border-radius: 20px;
        padding: 20px 32px;
        flex: 0 0 48%;
        background-position: right bottom;
        background-repeat: no-repeat;
        min-height: 240px;

        display: flex;
        flex-direction: column;
        justify-content: space-around;

        &:nth-child(1) {
          background-image: url(../img/coupons/novosel.svg);
        }

        &:nth-child(2) {
          background-image: url(../img/coupons/gatchina.png);
        }

        &:nth-child(3) {
          background-image: url(../img/coupons/pension.svg);
        }

        &:nth-child(4) {
          background-image: url(../img/coupons/mnogodet.svg);
        }

        &.coupon__fiolet {
          background-color: $fiolet;
          color: $yellow;
        }

        &.coupon__yellow {
          background-color: $yellow;
          color: $fiolet;

          .coupon-btn {
            background-color: $fiolet;
            color: $yellow;
          }
        }

        .coupon-btn {
          width: 45%;
          @include adaptiv-font(14, 12);
          border-radius: 8px;

          &:hover {
            border-radius: 50px;
          }
        }

        h3 {
          font-weight: 700;
          @include adaptiv-font(40, 24);
          width: 70%;
        }

        p {
          font-weight: 600;
          @include adaptiv-font(20, 14);
          width: 50%;

          span {
            @include adaptiv-font(36, 20);
          }
        }
      }
    }
  }
}


// responsive/////////////////////////////////////////////////////////////////

@media (max-width: 1400px) and (min-width: 900.1px){
  .coupons-section{
    .container {
      .coupons {
        padding: 1%;
        .coupon {
          min-height: 220px;
          justify-content: flex-start;

          &:nth-child(1) {background-size: 50%;}
          &:nth-child(2) {background-size: 40%;}
          &:nth-child(3) {background-size: 55%;}
          &:nth-child(4) {background-size: 58%;}

          .coupon-btn {width: 40%;}

          h3 {width: 90%;}

          p {
            width: 60%;
            margin: 5% 0;
          }
        }
      }
    }
  }
}


@media (max-width: 900px) {
  .coupons-section{
    .container {
      >p{
        ol{
          padding: 0 5%;
          li{
            margin-top: 2%;
            ul{
              li{
                margin: 2% 4%;
                list-style-type: circle;
              }
            }
          }
        }
      }
      .coupons {
        padding: 2%;
        .coupon {
          padding-left: 4%;
          flex: 0 0 100%;
          min-height: 200px;
          justify-content: space-around;
          margin-top: 4%;
          &:nth-child(1) {background-size: 40%;}
          &:nth-child(2) {background-size: 30%;}
          &:nth-child(3) {background-size: 45%;}
          &:nth-child(4) {background-size: 48%;}

          .coupon-btn {width: 60%;}

          h3 {width: 100%;}

          p {
            width: 100%;
            margin: 1% 0;
          }
        }
      }
    }
  }
}