*{padding: 0;margin: 0;border: 0;}

*,*:before,*:after{
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

a:focus,a:active{outline: none;}

html,body, #root{
	width: 100%;
	height: 100vh;
	line-height: 1; 
	font-size: 10px;
	font-family: Circe, sans-serif;
}

.wrapper{
	min-height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
main{
	background-color: #FFFFF0;
	padding-top: 10%;
}
input,button,textarea{font-family:inherit;}

input::-ms-clear{display: none;}
button{cursor: pointer;}
button::-moz-focus-inner {padding:0;border:0;}
a, a:visited{text-decoration: none;}
a:hover{text-decoration: none;}
ul li{list-style: none;}
img{vertical-align: top; pointer-events: none;}

h1,h2,h3,h4,h5,h6{font-size:inherit;}

//Mixin responsive font-size
$maxWidth: 1920;
@mixin adaptiv-font($pcSize, $mobSize) {
	$addSize: $pcSize - $mobSize;
	$addMobSize: $addSize + $addSize * 0.7;
	@media (max-width: 767px) {
		font-size: calc(#{$mobSize + px} + #{$addMobSize} * ((100vw - 320px) / #{$maxWidth}));
		line-height: calc(#{$mobSize + px} + #{$addMobSize} * ((100vw - 320px) / #{$maxWidth}));;
	}
	@media (min-width: 767px) {
		font-size: calc(#{$mobSize + px} + #{$addSize} * (100vw / #{$maxWidth}));
		line-height: calc(#{$mobSize + px} + #{$addSize} * (100vw / #{$maxWidth}));
	}
}

@mixin adaptiv-font-h1($pcSize, $mobSize) {
	$addSize: $pcSize - $mobSize;
	$addMobSize: $addSize + $addSize * 0.1;
	@media (max-width: 1024px) {
		font-size: calc(#{$mobSize + px} + #{$addMobSize} * ((100vw - 320px) / #{$maxWidth}));
		line-height: calc(#{$mobSize + px} + #{$addMobSize} * ((100vw - 320px) / #{$maxWidth}));;
	}
	@media (min-width: 1024px) {
		font-size: calc(#{$mobSize + px} + #{$addSize} * (100vw / #{$maxWidth}));
		line-height: calc(#{$mobSize + px} + #{$addSize} * (100vw / #{$maxWidth}));
	}
}


//Переменные цветов
$white: #FFFFF0;
$dark:#2E363E;
$fiolet:#5E415F;
$yellow:#FAF0A7;
$light-yellow :#FFFFDF;

.container{
	max-width: 1400px;
	margin: 0 auto;
}
.flex-container{
	display:flex;	
}
a {
	transition: all .3s ease-in-out!important;
}
p{
	font-style: normal;
	font-weight: 300;
	@include adaptiv-font(16, 12);
	margin-bottom: 2%;
}
//Шаблоны
%d-flex{
	display: flex;
	justify-content: space-around;
}
.btn{
	padding: 2% 4%;
	border-radius: 15px;
	font-weight: 400;
	@include adaptiv-font(24, 12);
	transition: all .6s ease-in-out !important;
	color: $fiolet;
	background-color: $yellow;
	border: 1px solid transparent;
	&:hover{
		border-radius: 50px;
		background-color: $yellow;
		color: $fiolet;
		border: 1px solid $fiolet;
		box-shadow: 4px 8px 15px rgba(0, 0, 0, 0.15)!important;
	}
	&:disabled{
		background-color: $white !important;
		color: #B5B5B2 !important;
		border: 1px solid #B5B5B2  !important;
		&:hover {
			box-shadow: none !important;
			border: 1px solid #B5B5B2  !important;
			border-radius: 15px !important;

			cursor: url('../img/cursors/not-allowed.svg') 4 1,
					url('../img/cursors/not-allowed.png') 4 1,
					not-allowed ;
		}
	}
}
@media (max-width: 900px) and (min-width: 769px) {
	.btn{
		padding: 4%;
		border-radius: 10px;
	}
	main{
		padding-top: 7%;
	}
}
@media (max-width: 768px) and (min-width: 450px){
	.btn{
		padding: 3%;
		border-radius: 8px;
	}
	main{
		padding-top: 10%;
	}
}
@media (max-width: 449px){
	.btn{
		padding: 4%;
		border-radius: 5px;
	}
	main{
		padding-top: 15%;
	}
}


//scroll bar
::-webkit-scrollbar-button {
background-image:url('');
background-repeat:no-repeat;
width:8px;
height:0;
}

::-webkit-scrollbar-track {
background-color:#ecedee
}

::-webkit-scrollbar-thumb {
-webkit-border-radius: 0;
border-radius: 0;
background-color: $fiolet;
}

::-webkit-scrollbar-thumb:hover{
background-color: $yellow;
}

::-webkit-resizer{
background-image:url('');
background-repeat:no-repeat;
width:8px;
height:0;
}

::-webkit-scrollbar{
width: 8px;
}