.container__404 {
  padding-top: 20% !important;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  h1{
    font-weight: normal;
    @include adaptiv-font(36, 24);
    text-align: center;
    color: #333333;
  }
  .btn__404{
    margin-top: 5%;
    width: 30%;
    padding: 2%;
    font-size: 2.4em;
    box-shadow: 0 20px 30px rgba(68, 140, 246, 0.35);
    //border-radius: 30px;
    //border: 1px solid $fiolet;
    //&:hover{
    //  transform: none !important;
    //  border-radius: 20px;
    //  background-color: $white;
    //  color: $fiolet;
    //}
  }
}