.partners{
  //margin-top: 5%;

  p{text-align: center;}
  .container{
    padding-bottom: 5%;
    background-color: #FFFFFF;
    border-radius: 22px;
    //padding: 2% ;
    margin: 4%;
    box-shadow: (4px 4px 20px rgba(0, 0, 0, 0.51));
    .flex-container{

      justify-content: center;
      align-items: center;
      img {
        padding: 2%;
        height: auto;
      }
    }
  }
}
// responsive/////////////////////////////////////////////////////////////////


@media(max-width: 900px){
  .partners{
    .container{
      .flex-container{
        img {
          width: 20%;
        }
      }
    }
  }
}