.reviews{
  margin-bottom: 5%;
  p{
    text-align: center;
    margin-top: 2%;
    a{
      color: #624063;
      text-decoration: underline;
      cursor: pointer;
      &:hover{
        font-weight: bold;
      }
    }
  }
  .swiper{
    padding-top: 3%;
    .swiper-button-next, .swiper-button-prev {
      transform: translateY(0) !important;
    }
    .swiper-wrapper{
      width: 90%;
    }
  }
  .swiper-slide{
    background: #FFFFFF;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    text-align: center;
    align-items: center;
    //max-width: 250px;
    min-height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1.5%;
    img{
      width: 40%;
    }
    h3{
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 17px;
      margin: 5% 0;
      color: #624063;
      text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    }
    p{
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #222222;
      padding: 16px;
    }
    span{
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      color: #B7B7B7;
    }
  }
}
// responsive/////////////////////////////////////////////////////////////////


@media(max-width: 900px){
  .reviews {
    > p{
      font-size: 16px !important;
      line-height: 24px !important;
      font-weight: 400 !important;
    }
    .swiper{
      .swiper-wrapper{
        padding-bottom: 5%;
      }
    }
    .swiper-button-next,.swiper-button-prev {display: none}
    .swiper-slide {
      max-width: 400px;
      padding: 1.5%;
      box-shadow: none;
      border: 1px solid #624063;
      img{
        width: 30%;
        padding-top: 10%;
      }
      h3{
        margin: 0;
      }
      p{
        padding: 0 2%;
      }
      span{
        margin-bottom: 5%;
      }
    }

    .reviews__container  {
      padding-bottom: 10%;
    }
  }
}