.modal{
  z-index: 6;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;

  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .3s ease-in-out .1s;

  &.active{
    visibility: visible;
    transform: scale(1);
    background-color: rgba(30, 40, 51, 0.25);
    backdrop-filter: blur(15px);
  }

  .modal-content{
    transition: all .4s ease-in-out .2s;
    transform: scale(0);
    transform: translateX(-100vw);
    background: white;
    border-radius: 16px;
    width: 800px;
    padding: 40px;
    &.active{
      transform: scale(1);
    }
    .close{
      position: relative;
      /* Это важно, если псевдоэлемент нужно позиционировать относительно этого блока */

      width: 10px;
      height: 10px;
      left: 95%;
      cursor: pointer;
      transition: all 1s ease-in-out;

      &:after{
        content: "";
        /* В любом случае необходим, даже пустой. Иначе не будет работать */
        position: absolute;
        width: 20px;
        height: 20px;
        background-image: url("../img/close-icon.svg");
        background-size: 20px 20px;
        background-repeat: no-repeat;
      }
    }
    form{
      text-align: center;
      h1{
        font-weight: bold;
        @include adaptiv-font(48, 22);
        color: $dark;
      }
      .form-group{
        display: flex;
        flex-direction: column;
        margin: 40px auto;
        width: 80%;
        max-height: 60vh;
        overflow-x: auto;
        padding: 8px;
        label{
          @include adaptiv-font(16, 14);
          text-align: left;
          color: $dark;
          margin-bottom: 8px;
        }
        input{
          margin-bottom: 10px;
          display: flex;
          flex-direction: column;
          outline: none;
          &:active, &:focus{
            border-color: #624063;
          }
        }
        input, textarea{
           padding: 16px 24px;
           border: 1px solid #C7D7EA;
           box-sizing: border-box;
           border-radius: 6px;
           background-color: transparent;
            &.error{
              border: 1px solid red;
              //animation: 1.2s ease-in-out 0s normal none infinite running trambling-animation;
              &::placeholder{
                color: red;
              }
            }
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .modal{
    .modal-content{
      width: 100%;
      height: 100vh;
      padding: 4%;
      border-radius: 0;
      form{
        text-align: center;
        margin-top: 15%;
        h1{
          font-weight: bold;
          @include adaptiv-font(48, 22);
          color: $dark;
        }
        .form-group{
          margin: 0 auto;
          width: 100%;
          max-height: 80vh;
          padding: 0 0 16px;
          label{

          }
          textarea{
            height: 150px;
          }
          input, textarea{
            padding: 5%;
          }
        }
        .btn{
          width: 100%;
          padding: 5%;
        }
      }
    }
  }
}

@keyframes trambling-animation {
  0%, 50%, 100% {
    transform: rotate(0deg);
  }
  20%, 30% {
    transform: rotate(-1deg);
  }
  20%, 40% {
    transform: rotate(1deg);
  }
}