.header{
	box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.51);
	position: fixed;
	width: 100%;
	z-index: 5;
}
.top-header{
	background-color: $fiolet;

	.flex-container{
		width: 100%;
		justify-content: center;
		padding: .7% 0;

		a{
			font-style: normal;
			font-weight: 400;
			@include adaptiv-font(14, 10);
			line-height: 21px;
			padding: 0 2%;
			color: $yellow;
			display: flex;
		    align-items: center;

		    &:hover{
				color: #ffffff;
				img{
					filter: brightness(2);
				}
		    }
		    img{
		    	margin-right: 8px;
		    }
		}
	}
}
.header-body{
	background-color: $yellow;

	.flex-container{
		width: 100%;
		align-items: center;
		padding: .5% 0 ;
	}	
	
	.header__navigation{
		flex: 100% 0 0;	
		.navigation{
			.menu{
				@extend %d-flex;
				align-items: center;
				li{
					.menu-icon{display: none}
					a{
						cursor: pointer;
						@include adaptiv-font(18, 12);
						color: $fiolet;

						&.active, &:hover {
							text-shadow:  0 0 0 #222222, 0 0 0 #222222;;

						}
					}
				}
				.callback-btn {
					background-color: $fiolet;
					color: $yellow;
					padding: .8% 2%;
				}
			}	
		}
	}
}

// responsive/////////////////////////////////////////////////////////////////

.header__bg, .header__burger, .logo-txt, .call-btn{display: none;}

@media(max-width: 900px){
	.top-header{display: none;}
	.header__burger {display:block;}

	.header-body {
		.flex-container{
			justify-content: space-between;
			padding: 3% 0;
			>.logo-txt{
				display: block;
				margin-right: 10px;
			}
		}
	}
	.header__navigation{
		overflow: auto;
		position: absolute;
		width: 70%;
		height: 100vh;
		top: 0;
		left: 0;
		z-index: -1;
		background: $white;
		transform: translate(-100%);

		&.active{
			box-shadow: 0px 2px 15px #222222;
			transform: translate(0);
		}

		.navigation{
			padding-top: 80px;
			.menu{
				flex-direction: column;
				//justify-content: flex-start;
				align-items: flex-start;
				> a {
					align-self: flex-start;
					margin: 16px ;
				}
				li{
					padding: 16px;
					border-bottom: none;
					align-self: flex-start;
					display: flex;
					align-items: center;

					.menu-icon{
						display: flex!important;
						margin-right: 16px;
					}
					a, p{
						color: $dark !important;
						font-size: 20px !important;
						font-weight: normal;
					}

					&:hover, &.active-link{
						>a,p{
							border-bottom: none;
							text-shadow: none;
						}
					}
				}
				.callback-btn, .call-btn {
					display: flex;
					align-self: flex-start;
					margin-left: 16px;
					margin-top: 16px;
					padding: 16px !important;
					font-size: 20px !important;
				}
			}
		}
	}
}


// burger/////////////////////////////////////////////////////////////////
.header-body{
	.call-me{
		margin-right: 10px;
		a{
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 24px;
			letter-spacing: 0.05em;
			color: $fiolet;
		}
	}
	.header__burger{
		position: relative;
		width: 22px;
		height: 22px;
		top: 0;
		right: -10px;
		p{
			left: 30px;
			position: relative;
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 24px;
			text-transform: uppercase;
			color: $fiolet;
		}
	}
	.header__burger span{
		position: absolute;
		background-color: $fiolet;
		left: 0;
		width: 64%;
		height: 3px;
		border-radius: 1px;
		top: 8.5px;
	}
	.header__burger:before,
	.header__burger:after,
	.header__burger span,
	.header__navigation{
		-o-transition: all 0.6s ease-in-out 0s;
		-moz-transition: all 0.6s ease-in-out 0s;
		-webkit-transition: all 0.6s ease-in-out 0s;
		transition:all 0.3s ease-in-out 0s;
	}

	.header__burger:before,
	.header__burger:after{
		content: '';
		background-color: $fiolet;
		position: absolute;
		width: 100%;
		height: 3px;
		border-radius: 1px;
		right: 0;
		top: 0;
	}
	.header__burger:before{
		bottom: 0;
	}
	.header__burger:after{
		top: 16px;
	}
	.header__burger.active:after{
		transform: rotate(-45deg);
		top: 7px;
	}
	.header__burger.active:before{
		transform: rotate(45deg);
		top: 7px;
	}
	.header__burger.active span{
		transform: scale(0);
		opacity: 0
	}
}